import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,{attrs:{"id":"update-password-container","fluid":"","tag":"section"}},[_c('base-material-card',{ref:"form",staticClass:"px-5 py-3",attrs:{"icon":"mdi-lock-reset-outline"}},[_c(VCardText,[(_vm.errors.length)?_c(VCardText,_vm._l((_vm.errors),function(error){return _c(VAlert,{key:error.id,attrs:{"color":"error","dark":"","outlined":"","show":"","variant":"danger"}},[_vm._v(" "+_vm._s(error)+" ")])}),1):_vm._e(),(_vm.success)?_c(VAlert,{attrs:{"color":"success","dark":"","outlined":""}},[_vm._v(" Your password has been changed successfully. ")]):_vm._e(),(!_vm.success)?_c(VForm,{ref:"updatePasswordForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c(VTextField,{attrs:{"label":"Current Password","type":"password","validate-on-blur":"","rules":_vm.rules.required},model:{value:(_vm.currentPassword),callback:function ($$v) {_vm.currentPassword=$$v},expression:"currentPassword"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c(VTextField,{attrs:{"label":"New Password","type":"password","validate-on-blur":"","rules":_vm.rules.newPassword},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c(VTextField,{attrs:{"label":"Re-Enter New Password","type":"password","rules":_vm.rules.confirmNewPassword},model:{value:(_vm.confirmNewPassword),callback:function ($$v) {_vm.confirmNewPassword=$$v},expression:"confirmNewPassword"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c('div',{staticClass:"my-2"},[_c(VBtn,{attrs:{"color":"primary","dark":"","type":"submit"}},[_vm._v(" Submit ")])],1)])],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }